import React, { useState, useEffect } from "react";
const Header = ({resumeData}) => {

	const [isActive, setActive] = useState("");
	const toggleClass = (e) => {
		setActive(e.currentTarget.hash);
	};
	useEffect(() => {
		setActive(window.location.hash)
	});
	return (
		<React.Fragment>
			<div className="header-overlay"></div>
			<header id="home">
				<nav id="nav-wrap">
					<a className="mobile-btn rotate-90-cw" href="#nav-wrap" title="Show navigation">
						Show navigation
					</a>
					<a className="mobile-btn rotate-90-cw" href="/#" title="Hide navigation">
						Hide navigation
					</a>
					<ul id="nav" className="nav fade-in-top">
						<li className={isActive === "#home" ? "current" : ""} >
							<a href="#home" onClick={toggleClass}>
								Home
							</a>
						</li>
						<li className={isActive === "#about" ? "current" : ""} >
							<a href="#about" onClick={toggleClass}>
								About
							</a>
						</li>
						<li className={isActive === "#projects" ? "current" : ""} >
							<a href="#projects" onClick={toggleClass}>
								Projects
							</a>
						</li>
						<li className={isActive === "work" ? "current" : ""} >
							<a href="#work" onClick={toggleClass}>
								work
							</a>
						</li>
						<li className={isActive === "#contact" ? "current" : ""} >
							<a href="#contact" onClick={toggleClass}>
								Contact
							</a>
						</li>
					</ul>
				</nav>

				<div className="row banner">
					<div className="banner-text">
						<h1 className="responsive-headline">
							{resumeData.name}
						</h1>
						<h3 style={{ color: "#fff", fontFamily: "sans-serif " }}>
							Designer and Director.
						</h3>
						<hr />
						<ul className="social">
							{resumeData.socialLinks &&
								resumeData.socialLinks.map(item => {
									return (
										<li key={item.name}>
											<a href={item.url} target="_blank" rel="noopener noreferrer">
												<i className={item.className}></i>
											</a>
										</li>
									);
								})}
						</ul>
					</div>
				</div>

				<p className="scrolldown">
					<a className="smoothscroll" href="#about">
						<i className="icon-down-circle"></i>
					</a>
				</p>
			</header>
		</React.Fragment>
	);
}
export default Header
