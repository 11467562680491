import React from "react";
const Work = resumeData => {
	const {resumeData: { work: jobs }} = resumeData;
	return (
		<section id="work" className="work-section">
			<div className="row work section-content-spacer">
				<div className="three columns header-col">
					<h1>Work</h1>
				</div>

				<div className="nine columns main-col resume">
					<div className="work-wrapper">
						<div className="work-description">
						<p>Some of the companies that I've worked at or done work for.</p>
						</div>
						<div className="jobs">
						{jobs.map((job, i) => (
							<a href={job.link} target="_blank" rel="noopener noreferrer" key={i}>
								<img src={job.logo} alt={job.CompanyName} />
							</a>
						))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Work;
