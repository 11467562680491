let resumeData = {
	imagebaseurl: "",
	name: "Jonathan Tessler",
	role: "Designer and Director.",
	linkedinId: "https://www.linkedin.com/in/jontessler/",
	whoami:
		"I'm a creative director and graphic designer with a deep background and expertise in user interfaces, web & app design, user experience, branding, communications strategy, b2b and consumer messaging.",
	whatdoido:
		"As a designer and creative problem solver, I enjoy making things that connect companies and audiences. As a design lead, I enjoy helping teammates achieve successful outcomes. And as a seasoned art director, I ensure that all visual and technical aspects of a project come together beautifully.",
	address: "New York",
	education: [{}],
	work: [
		{
			CompanyName: "Saatchi & Saatchi Wellness",
			logo:
			"./images/ssw.svg",
			link:
				"https://www.saatchiwellness.com/#home"
		},
		{
			CompanyName: "CBS",
			logo:
				"./images/cbs.svg",
			link: "https://www.cbsnews.com/newyork/"
		},
		{
			CompanyName: "E*Trade",
			logo:
				"./images/etrade.svg",
			link: "https://us.etrade.com/home"
		},
		{
			CompanyName: "Scholastic",
			logo:
				"./images/scholastic.svg",
			link: "https://www.scholastic.com/home/"
		},
		{
			CompanyName: "mtv.com",
			logo:
				"./images/mtv.svg",
			link: "https://www.mtv.com/"
		},
		{
			CompanyName: "The New York Times",
			logo:
				"./images/nyt.svg",
			link: "https://www.nytimes.com/"
		},
		{
			CompanyName: "Microsoft",
			logo:
				"./images/microsoft.svg",
			link: "https://www.microsoft.com/video/"
		}
	],
	skillsDescription: "Your skills here",
	skills: [
		{
			skillname: "UX/UI Design"
		},
		{
			skillname: "Creative Direction"
		},
		{
			skillname: "Graphic Design"
		},
		{
			skillname: "Reactjs"
		}
	],
	portfolio: [
		{
			name: "Aklief Cream / Galderma",
		 	description:
		 		"<strong>Aklief Cream</strong>, which contains trifarotene, is the first topical retinoid to get FDA approval for use on acne in over 20 years. Working with my creative and technology team at <strong>Saatchi & Saatchi Wellness</strong>, I was tasked with final art direction, creating the overall UI, and producing the digital style guide for this responsive site. Primary tools were Sketch, Photoshop, Zeplin, and MS Teams. <strong>Launch the <a href='https://www.aklief.com/' target='_blank'>AKLIEF® Cream website here</a></strong>.",
				 imageurl: "./images/aklief-desktop-mock.png",
		 	link: "https://www.aklief.com/"
		 },
		 {
			name: "The Children’s Place / Gymboree",
		 	description:
		 		"<strong>Lead UX/UI Design</strong> for <strong>e-commerce sites</strong> for <strong><a href='http://childrensplace.com/' target='_blank'>The Children’s Place</a></strong> and sister-brand <strong><a href='http://gymboree.com/' target='_blank'>Gymboree</a></strong>. My work dealt with the overarching visual branding, account creation and returning customer pages, a tight focus on the <strong>cart & checkout flow</strong>, and the main UI design for the accompanying app. Our team employed an adapted atomic design methodology with an agile/scrum process.<br><br>Primary tools were Sketch, Photoshop, InVision DSM, Zeplin, Slack, and JIRA.",
				 imageurl: "./images/tcp-desktop-mock.png",
		 	link: "http://childrensplace.com/"
		 },
		 {
			name: "E*Trade Financial",
		 	description:
		 		"<strong>Creative & art direction, and UX/UI design</strong> for digital, print & customer marketing, collateral, motion, environmental and branding work for well-known online financial firm. Partnered with internal marketing teams and external agencies on the 2016 design and launch of <strong>E*Trade’s <a href='https://www.etrade.com/' target='_blank'>consumer prospect site</a></strong> as well as their innovative robo product, <strong><a href='https://us.etrade.com/what-we-offer/our-accounts/core-portfolios' target='_blank'>Adaptive Portfolio</a></strong>. Primary tools were Sketch, Photoshop, InVision, Slack, and JIRA.",
				 imageurl: "./images/etrade-desktop-mock.png",
		 	link: "https://etrade.com/"
		 },
		 {
			name: "Scholastic",
		 	description:
		 		"<strong>Lead UX/UI Design</strong> for two <strong>e-commerce </strong> platform redesigns/relaunches: developed user journeys, flows, wireframes, interaction design, UI, and overarching design systems. Creative brainstorm sessions, stakeholder pitches, and presentations, usability testing, and competitive analysis. Agile/scrum process. Primary tools were Illustrator, Photoshop, InVision, Slack, and JIRA. Brand design has changed since launch but my architecture for SERP experience still remains. <strong><a href='https://teachables.scholastic.com/' target='_blank'>Scholastic Printables (Teachables)</a></strong>",
				 imageurl: "./images/scholastic-desktop-mock.png",
		 	link: "https://teachables.scholastic.com/"
		 },
		 {
			name: "Amazing Colossal Merch!",
		 	description:
		 		"<strong>Working directly with the show's producer and co-host, Frank Santopadre</strong>, I created a brand architecture and visual style to dovetail with my existing design for the logo of the highly-rated <strong><a href='https://www.gilbertpodcast.com/' target='_blank'>Gilbert Gottfried's Amazing Colossal Podcast</a></strong>. Developer <strong><a href='https://www.linkedin.com/in/mattalter/' target='_blank'> Matt Alter </a></strong> then customized liquid templates on Shopify for this <strong>e-commerce site</strong>. The site was wireframed and designed in Figma, with assists from Zeplin and Slack. It's truly <strong><a href='https://www.amazingcolossalmerch.com/' target='_blank'>Amazing Colossal Merch!</a></strong>",
				 imageurl: "./images/acm-desktop-mock.png",
		 	link: "https://amazingcolossalmerch.com/"
		 },
		 {
			name: "CBS / Radio.com",
		 	description:
		 		"<strong>2013-2018</strong>: <strong>CBS's</strong> award-winning <strong>Radio.com product</strong> featured over 100 streaming terrestrial, HD, and exclusive online stations offering a variety of music, sports, and talk genres.<br><br><strong>Lead UI and visual design, brand identity, and creative strategy</strong> for mobile, desktop and app for streaming platform. Worked with UX to develop user journeys, flows, wireframes, interaction design, and overarching design systems. Creative brainstorm sessions, stakeholder pitches, and presentations, usability testing, and competitive analysis. Agile/scrum process. Primary tools were Photoshop, Illustrator, InVision, Slack, and JIRA. The site and properties were sold to Entercom in 2017 and integrated into current <strong><i>audacy.com</i></strong> product.",
				 imageurl: "./images/cbs-desktop-mock.png",
		 	link: "./images/cbs-desktop-mock.png"
		 },
		 {
			name: "Laurence Fishburne Site",
			description:
				"<strong>2003-2021:</strong> The official website for actor, director, writer, and producer <strong>Laurence Fishburne</strong>.",
			imageurl: "./images/fishburne-mobile.png",
			link: "./images/fishburne-mobile.png"
		}
	],
	testimonials: [
		{
			description: "",
			name: ""
		}
	],
	contact: [
		{
			link: "https://www.linkedin.com/in/jontessler",
			logo: "../images/linkedin.png"
		},
	]
};

export default resumeData;
